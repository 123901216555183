import PropTypes from 'prop-types';
import { formatDistanceStrict } from 'date-fns';
import { Avatar, avatarClasses, AvatarGroup, Box, Stack, SvgIcon, Typography } from '@mui/material';
import { customLocale } from 'src/utils/date-locale';
import { useAuth } from 'src/hooks/use-auth';

import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';


export const ChatThreadItem = (props) => {
    const { active, thread, ambassadors, onSelect, ...other } = props;
    const { user } = useAuth();

    // TODO error handling if thread.ambassador.id is not found
    const recipientID = ambassadors.allIds.find((id) => thread.ambassador.id === id);
    const recipient = ambassadors.byId[recipientID];
    const threadLastMessageTime = thread.id === "dummyThread" ? thread.lastMessageTime : thread.lastMessageTime.toDate();
    const lastActivity = formatDistanceStrict(threadLastMessageTime, new Date(), {
        addSuffix: false,
        locale: customLocale
    });
    const displayName = recipient.firstName;
    const displayContent = `${thread.lastMessageSender.id === user?.id ? "Me: " : ""}${thread.lastMessageContent}`;
    const groupThread = false;
    const isUnread = !!(thread.prospectUnreadCount && thread.prospectUnreadCount > 0) && thread.lastMessageSender.id !== user?.id;
    
    return (
        <Stack
            component="li"
            direction="row"
            onClick={onSelect}
            spacing={2}
            sx={{
                borderRadius: 2.5,
                cursor: 'pointer',
                px: 3,
                py: 2,
                '&:hover': {
                    backgroundColor: 'action.hover'
                },
                ...(active && {
                    backgroundColor: 'action.hover'
                })
            }}
            {...other}>
            <div>
                <AvatarGroup
                    max={2}
                    sx={{
                        [`& .${avatarClasses.root}`]: groupThread
                            ? {
                                height: 26,
                                width: 26,
                                '&:nth-of-type(2)': {
                                    mt: '10px'
                                }
                            }
                            : {
                                height: 36,
                                width: 36
                            }
                    }}
                >
                    <Avatar
                        key={recipient.ogID}
                        src={recipient.photoURL || undefined}
                    />
                </AvatarGroup>
            </div>
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden'
                }}
            >
                <Typography
                    noWrap
                    variant="subtitle2"
                >
                    {displayName}
                </Typography>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    {isUnread && (
                        <Box
                            sx={{
                                backgroundColor: 'primary.main',
                                borderRadius: '50%',
                                height: 8,
                                width: 8
                            }}
                        />
                    )}
                    <Typography
                        color="text.secondary"
                        noWrap
                        sx={{ flexGrow: 1, width: "190px !important" }}
                        variant="subtitle2"
                    >
                        {displayContent}
                    </Typography>
                </Stack>
            </Box>
            <Stack sx={{ ml: "0px !important", width: "28px" }}>
                {lastActivity && (
                    <Typography
                        color="text.secondary"
                        sx={{ whiteSpace: 'nowrap', alignSelf: 'center'}}
                        variant="caption"
                    >
                        {lastActivity}
                    </Typography>
                )}
                {thread.prospectMuted && (
                    <SvgIcon sx={{ color: "lightgray", width: "27px" }}>
                        <NotificationsOffIcon />
                    </SvgIcon>
                )}
            </Stack>
        </Stack>
    );
};

ChatThreadItem.propTypes = {
    active: PropTypes.bool,
    onSelect: PropTypes.func,
    thread: PropTypes.object.isRequired
};

ChatThreadItem.defaultProps = {
    active: false
};

import { Box, Button, SvgIcon, Typography } from '@mui/material';
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import PropTypes from 'prop-types';


export const ChatBlank = ({ onTabChange }) => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'hidden',
            paddingBottom: 20,
            marginTop: 10,
        }}
    >
        <Box
            // component="img"
            // src="/assets/errors/error-404.png"
            sx={{
                height: 'auto',
                maxWidth: 120
            }}
        >
            <SvgIcon sx={{ height: 120, width: 120 }}>
                <ChatBubbleTwoToneIcon />
            </SvgIcon>
        </Box>
        <Typography
            color="text.secondary"
            sx={{ my: 2 }}
            variant="subtitle1"
        >
            Start a new conversation
        </Typography>
        <Button
            onClick={() => onTabChange({
                whichTab: 'students',
            })}
            startIcon={(
                <SvgIcon>
                    <PlusIcon />
                </SvgIcon>
            )}
            variant="contained"
        >
            Browse Ambassadors
        </Button>
    </Box>
);

ChatBlank.propTypes = {
    onTabChange: PropTypes.func
};


import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, Container, Grid, Stack, Typography } from '@mui/material';
import { CollectionSearch } from 'src/components/collection-search';
import { countries, canadianProvinces } from 'src/utils/locations';
import { useAuth } from 'src/hooks/use-auth';
import { setDoc, collection, doc } from "firebase/firestore";

import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { MuiPhone } from 'src/components/mui-phone';
import { analytics, db } from 'src/libs/firebase';
import { logAnalyticsEvent } from 'src/utils/logging';

export default function ChatPostMessageForm({ ambassadorName, activeSchool, open, onClose, onSkip, onSubmit }) {
    const [notificationPreference, setNotificationPreference] = useState(null);
    const [phone, setPhone] = useState('');
    const [agreedToMarketing, setAgreedToMarketing] = useState(true);
    const schoolName = activeSchool?.name ?? "the school";

    const marketingEmailsPermissions = `Join the email list to stay informed about recruitment insights and news from ${schoolName}!`;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();

                    onClose();
                },
            }}
        >
            <DialogTitle variant='h3' >How do you want to be notified when {ambassadorName ?? "the ambassador"} responds?</DialogTitle>
            <DialogContent sx={{ textAlign: "-webkit-center" }}>
                <DialogContentText>

                </DialogContentText>
                <Stack spacing={2} mt={2} width={"250px"} justifyContent={"center"}>
                    <Button variant="outlined" color='error' onClick={() => setNotificationPreference("none")}>Do not notify me when they respond</Button>
                    <Button variant={notificationPreference === "SMS" ? "contained" : "outlined"} onClick={() => setNotificationPreference("SMS")}>SMS Only</Button>
                    <Button variant={notificationPreference === "email" || !notificationPreference ? "contained" : "outlined"} onClick={() => setNotificationPreference("email")}>Email Only</Button>
                    <Button variant={notificationPreference === "both" ? "contained" : "outlined"} onClick={() => setNotificationPreference("both")}>Both Email and SMS</Button>
                </Stack>
                {
                    (notificationPreference === "SMS" || notificationPreference === "both") && (
                        <Box sx={{ mt: 4, width: "250px" }}>
                            <MuiPhone
                                value={phone}
                                onChange={(phone) => setPhone(phone)}
                            />
                        </Box>

                    )
                }
                <Stack direction="row" mt={3} sx={{ alignItems: "flex-start" }}>
                    <Checkbox onChange={(e) => setAgreedToMarketing(e.target.checked)} checked={agreedToMarketing} sx={{ pt: 0 }} inputProps={{ 'aria-label': marketingEmailsPermissions }} defaultChecked />
                    <Typography variant="body1" color="text.secondary" textAlign="left">
                        {marketingEmailsPermissions}
                    </Typography>
                </Stack>

            </DialogContent>
            <DialogActions>
                <Button
                    sx={{ color: "gray" }}
                    onClick={() => {
                        logAnalyticsEvent('post_message_form_skipped', { school: activeSchool.id });
                        onSkip();
                        onClose();
                    }}
                >
                    Skip for now
                </Button>
                <Button
                    disabled={!notificationPreference || ((notificationPreference === "SMS" || notificationPreference === "both") && (!phone || phone.length < 10))}
                    type="submit"
                    size='large'
                    onClick={() => {
                        onSubmit({ notificationPreference, phone, agreedToMarketing });
                        onSkip();
                        onClose();
                    }}
                >
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
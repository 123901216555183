import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Stack } from '@mui/material';
import { chatApi } from 'src/api/chat';
import { Scrollbar } from 'src/components/scrollbar';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { useDispatch, useSelector } from 'src/store';
import { thunks } from 'src/thunks/chat';
import { ChatMessageAdd } from './chat-message-add';
import { ChatMessages } from './chat-messages';
import { ChatThreadToolbar } from './chat-thread-toolbar';
import { subDays, subHours, subMinutes } from 'date-fns';
import { useAuth } from 'src/hooks/use-auth';
import { useMounted } from 'src/hooks/use-mounted';
import { collection, onSnapshot, serverTimestamp, addDoc, updateDoc, setDoc, doc, arrayUnion } from "firebase/firestore";
import { ChatMessageReply } from './chat-message-reply';
import { ChatMessageEdit } from './chat-message-edit';
import ChatPreMessageForm from './chat-pre-message-form';
import ChatPostMessageForm from './chat-post-message-form';
import { analytics, db } from 'src/libs/firebase';
import { logAnalyticsEvent } from 'src/utils/logging';

const useMessagesScroll = (messagesCount) => {
    const messagesRef = useRef(null);

    const handleUpdate = useCallback(() => {
        console.log("Messages Count: ", messagesCount);
        // Thread has no messages
        if (!messagesCount) {
            return;
        }

        // Ref is not used
        if (!messagesRef.current) {
            return;
        }

        const container = messagesRef.current;
        const scrollElement = container.getScrollElement();

        if (scrollElement) {
            setTimeout(() => {
                // Check if scrollElement is still available
                if (scrollElement) {
                    scrollElement.scrollTop = container.el.scrollHeight;
                }
            }, 150);
        }
    }, [messagesCount]);

    useEffect(() => {
        handleUpdate();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [messagesCount]);

    return {
        messagesRef
    };
};

const useMessages = (activeThreadID) => {
    const isMounted = useMounted();
    const [messages, setMessages] = useState({
        byId: {},
        allIds: []
    });

    const handleMessagesGet = useCallback(async () => {
        const messagesQuery = collection(db, `threads/${activeThreadID}/messages`);

        try {
            return onSnapshot(messagesQuery, async (snapshot) => {
                const docChanges = snapshot.docChanges();
                for (const change of docChanges) {
                    if (isMounted()) {
                        if (change.type === "added" || change.type === "modified") {
                            const messageData = change.doc.data();
                            if (messageData.time && !messageData.isDeleted) {
                                setMessages((prevState) => {
                                    const newAllIds = Array.from(new Set([...prevState.allIds, change.doc.id]));
                                    return {
                                        byId: {
                                            ...prevState.byId,
                                            [change.doc.id]: messageData
                                        },
                                        allIds: newAllIds
                                    };
                                });
                            }
                        }
                        if (change.type === "removed") {
                            setMessages((prevState) => {
                                const { [change.doc.id]: _, ...rest } = prevState.byId;
                                return {
                                    byId: rest,
                                    allIds: prevState.allIds.filter((id) => id !== change.doc.id)
                                };
                            });
                        }
                    }
                }

                if (activeThreadID !== "dummyThread") {
                    try {
                        // After retrieving the messages, update the prospectUnreadCount of the thread to be 0
                        const threadRef = doc(db, 'threads', activeThreadID);
                        await updateDoc(threadRef, {
                            prospectUnreadCount: 0
                        });
                    } catch (err) {
                        console.log("Failed to update prospect count for thread", activeThreadID);
                        console.error(err);

                        logAnalyticsEvent('error_prospect_count_thread', {
                            description: err.message,
                            thread: activeThreadID,
                        });
                    }
                }
            }, (err) => {
                console.error("Error fetching messages for thread", activeThreadID);
                console.error(err);

                logAnalyticsEvent('error_snapshot_messages', {
                    description: err.message,
                    thread: activeThreadID,
                });
            });
        } catch (err) {
            console.error(err);

            logAnalyticsEvent('error_fetching_messages', {
                description: err.message,
                thread: activeThreadID,
            });
        }

        return null;
    }, [isMounted, activeThreadID]);

    useEffect(() => {
        setMessages({
            byId: {},
            allIds: []
        });

        const unsubscribe = !!activeThreadID && activeThreadID !== "dummyThread" ? handleMessagesGet() : null;

        return () => {
            // Check if unsubscribe is a function before calling it
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [activeThreadID]);

    return messages;
};

export const ChatThread = (props) => {
    const { activeAmbassadorID, ambassadors, activeThreadID, activeThread, activeSchool, prospectMuted, onTabChange, showToggle, onToggleSideBar, ...other } = props;
    const dispatch = useDispatch();
    const { user, schoolSpecificUserInfo } = useAuth();
    const messages = useMessages(activeThreadID);
    const { messagesRef } = useMessagesScroll(messages.allIds.length);

    // const messagesRef = useRef(null);
    const [body, setBody] = useState('');

    const [replyMessageID, setReplyMessageID] = useState("");
    const [editMessageID, setEditMessageID] = useState("");

    const [tooManyMessagesWarning, setTooManyMessagesWarning] = useState("");
    const [messageTooLongWarning, setMessageTooLongWarning] = useState("");

    const [showPreMessageQuestions, setShowPreMessageQuestions] = useState(false);
    const [preMessageQuestionsSkipped, setPreMessageQuestionsSkipped] = useState(false);

    const [showPostMessageQuestions, setShowPostMessageQuestions] = useState(false);
    const [postMessageQuestionsSkipped, setPostMessageQuestionsSkipped] = useState(false);

    const [sendLoading, setSendLoading] = useState(false);

    const handleSend = useCallback(async (body) => {
        if (!user) {
            // Prompt the user to sign in
            onTabChange({ whichTab: "settings" });
            return;
        }
        // If user profile still not complete, prompt them first
        if ((!schoolSpecificUserInfo?.alreadyApplied || !schoolSpecificUserInfo?.plannedEnrollment || !schoolSpecificUserInfo?.selectedPrograms?.length || !schoolSpecificUserInfo?.selectedCountryOrProvince) && !preMessageQuestionsSkipped) {
            setShowPreMessageQuestions(true);
            return;
        }

        setSendLoading(true);

        if (!!editMessageID) {
            // Update the message
            const messageQuery = doc(
                db,
                `threads/${activeThreadID}/messages`,
                editMessageID
            );

            try {
                await updateDoc(messageQuery, {
                    content: body,
                    isEdited: true,
                });
            } catch (error) {
                console.error("Error updating message:", error);
            }
        } else if (!tooManyMessagesWarning && !messageTooLongWarning && body.length > 0) {
            // Check if we need to create the thread first
            let effectiveThreadID = activeThreadID;
            if (!activeThreadID || activeThreadID === "dummyThread") {
                // Create the thread
                const newThread = {
                    prospect: doc(db, "users", user.id),
                    prospectName: user.name,
                    ambassadorName: ambassadors.byId[activeAmbassadorID].firstName,
                    ambassadorAvatar: ambassadors.byId[activeAmbassadorID].photoURL,
                    ambassador: doc(db, "users", activeAmbassadorID),
                    contributors: [doc(db, "users", activeAmbassadorID)],
                    school: doc(db, "schools", activeSchool?.id),
                    schoolName: activeSchool?.name,
                    lastMessageContent: ambassadors.byId[activeAmbassadorID].profileIntroText,
                    lastMessageTime: new Date(),
                    lastMessageSender: doc(db, "users", activeAmbassadorID),
                    prospectUnreadCount: 0,
                    totalProspectMessages: 0,
                    type: "direct",
                    report: null,
                    created: new Date(),
                };

                logAnalyticsEvent('creating_thread', {
                    school: activeSchool?.id,
                    ambassador: activeAmbassadorID,
                });

                // Get a reference to the Firestore collection
                const collectionRef = collection(db, 'threads');
                // Add a new document with an automatically generated ID
                try {
                    const docRef = await addDoc(collectionRef, newThread);
                    effectiveThreadID = docRef.id;
                } catch (e) {
                    console.error('Error writing document: ', e);
                    logAnalyticsEvent('error_creating_thread', {
                        error: e,
                        school: activeSchool?.id,
                        ambassador: activeAmbassadorID,
                    });
                }
            }

            const replyMessageRef = !!replyMessageID ? doc(db, `threads/${effectiveThreadID}/messages`, replyMessageID) : null;
            const message = {
                content: body,
                sender: doc(db, "users", user.id),
                time: serverTimestamp(),
                isNotProspect: false,
                replyMessage: replyMessageRef,
                school: doc(db, "schools", activeSchool?.id),
            };

            logAnalyticsEvent('sending_message', {
                school: activeSchool?.id,
                ambassador: activeAmbassadorID,
                content: body,
                replying: !!replyMessageID,
                editing: !!editMessageID,
            });

            const messagesQuery = collection(db, `threads/${effectiveThreadID}/messages`);
            try {
                // Save new message to Firestore
                await addDoc(messagesQuery, message);
            } catch (err) {
                console.error(err);
                logAnalyticsEvent('error_sending_message', {
                    error: err,
                    school: activeSchool?.id,
                    ambassador: activeAmbassadorID,
                    content: body,
                });
            }
        }

        // Reset reply message ID
        setReplyMessageID("");
        // Reset edit message ID
        setEditMessageID("");
        // Reset the message body
        setBody("");

        setSendLoading(false);

        // Check if user has already set their notification preference
        if (!user.notificationPreference && !postMessageQuestionsSkipped) {
            // Prompt the user to set their notification preference
            setShowPostMessageQuestions(true);
        }
    }, [ambassadors, activeThread, schoolSpecificUserInfo, replyMessageID, editMessageID, body, showPreMessageQuestions, preMessageQuestionsSkipped, tooManyMessagesWarning, messageTooLongWarning, activeThreadID, postMessageQuestionsSkipped, showPostMessageQuestions, user]);

    const handleCommunicationsPreferences = useCallback(async (communicationPreferences) => {
        // Update the user's notification preference
        const { notificationPreference, phone, agreedToMarketing } = communicationPreferences;

        // Update the user within the prospects collection of the schools collection in Firestore
        const userDoc = doc(db, `users/${user.id}`);
        const updateData = {
            notificationPreference,
            phone,
        };

        if (agreedToMarketing) {
            updateData.agreedToMarketing = arrayUnion(activeSchool.id);
        }

        setDoc(userDoc, updateData, { merge: true });
    }, [activeSchool, user]);

    const handleMute = useCallback(async () => {
        if (!!activeThread && !!activeThreadID && activeThreadID !== "dummyThread" && !!activeSchool) {
            logAnalyticsEvent('muting_ambassador', {
                school: activeSchool?.id,
                ambassador: activeAmbassadorID,
            });
            // Update the thread doc to mute the prospect
            const threadQuery = doc(db, 'threads', activeThreadID);
            try {
                await updateDoc(threadQuery, {
                    prospectMuted: activeThread.prospectMuted ? false : true
                });
            } catch (error) {
                console.error("Error muting prospect:", error);
                logAnalyticsEvent('error_muting_ambassador', {
                    error: error,
                    school: activeSchool?.id,
                    ambassador: activeAmbassadorID,
                });
            }
        }
    }, [activeSchool, activeThread, user]);

    const handleReplyCancel = () => {
        setReplyMessageID("");
    };

    const handleEditCancel = () => {
        setEditMessageID("");
    };

    const handleChange = useCallback((event) => {
        if (!user) {
            // Prompt the user to sign in
            onTabChange({ whichTab: "settings" });
            return;
        }
        setBody(event.target.value);
    }, []);

    const replyMessageSender = messages.byId[replyMessageID]?.isNotProspect ? activeThread.prospectName : "Me";
    const replyMessageContent = messages.byId[replyMessageID]?.content;

    const editMessageDate = messages.byId[editMessageID]?.time?.toDate()
        .toLocaleDateString(undefined, {
            month: "numeric",
            day: "numeric",
            year: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        })
    const editMessageContent = messages.byId[editMessageID]?.content;

    const targetAmbassador = ambassadors.byId[activeAmbassadorID];
    const ambassadorName = targetAmbassador.firstName;

    useEffect(() => {
        if (!!replyMessageID) {
            setEditMessageID("");
        }
    }, [replyMessageID]);

    useEffect(() => {
        if (!!editMessageID) {
            setReplyMessageID("");
            setBody(messages.byId[editMessageID]?.content);
        } else {
            setBody("");
        }
    }, [editMessageID]);

    useEffect(() => {
        if (body.length > 1000) {
            setMessageTooLongWarning("Message is too long. Please keep it under 1000 characters.");
            logAnalyticsEvent('message_too_long', {
                school: activeSchool?.id,
                ambassador: activeAmbassadorID,
                content: body,
            });
        } else {
            setMessageTooLongWarning("");
        }
    }, [body]);

    useEffect(() => {
        if (tooManyMessagesWarning) {
            logAnalyticsEvent('too_many_messages', {
                school: activeSchool?.id,
                ambassador: activeAmbassadorID,
            });
        }
    }, [tooManyMessagesWarning]);

    return (
        <Stack
            sx={{
                flexGrow: 1,
                overflow: 'hidden'
            }}
            {...other}>
            <ChatThreadToolbar prospectMuted={prospectMuted} onMuteToggle={handleMute} onToggleSideBar={onToggleSideBar} showToggle={showToggle} targetAmbassadorID={activeAmbassadorID} ambassadors={ambassadors} />
            <Divider />
            <Box
                sx={{
                    flexGrow: 1,
                    overflow: 'hidden',
                    height: "60vh",
                }}
            >
                <ChatPreMessageForm open={showPreMessageQuestions} onClose={() => setShowPreMessageQuestions(false)} onSkip={() => setPreMessageQuestionsSkipped(true)} ambassadorName={targetAmbassador.firstName} activeSchool={activeSchool} />
                <ChatPostMessageForm open={showPostMessageQuestions} onClose={() => setShowPostMessageQuestions(false)} onSkip={() => setPostMessageQuestionsSkipped(true)} ambassadorName={targetAmbassador.firstName} activeSchool={activeSchool} onSubmit={handleCommunicationsPreferences} />
                <Scrollbar
                    ref={messagesRef}
                    sx={{ maxHeight: '100%' }}
                >
                    <ChatMessages
                        activeThreadID={activeThreadID}
                        onReply={(messageID) => {
                            setReplyMessageID(messageID);
                        }}
                        onEdit={(messageID) => {
                            setEditMessageID(messageID);
                        }}
                        messages={messages.allIds.length > 0 ? [
                            {
                                id: '5e867f0a5bc0ff2bfa07bfa6',
                                attachments: [],
                                body: ambassadors.byId[activeAmbassadorID].profileIntroText,
                                contentType: 'text',
                                authorId: activeAmbassadorID,
                                createdAt: null,
                            },
                            ...Object.entries(messages.byId).map(
                                ([key, message]) => ({
                                    id: key,
                                    attachments: [],
                                    body: message.content,
                                    contentType: 'text',
                                    authorId: message.sender.id,
                                    createdAt: message.time.toDate().getTime(),
                                    replyMessage: message.replyMessage,
                                    reactions: message.reactions,
                                    isEdited: message.isEdited,
                                })
                            )] : [
                            {
                                id: '5e867f0a5bc0ff2bfa07bfa6',
                                attachments: [],
                                body: ambassadors.byId[activeAmbassadorID].profileIntroText,
                                contentType: 'text',
                                authorId: activeAmbassadorID,
                                createdAt: new Date().getTime(),
                            }
                        ]}
                        ambassadors={ambassadors || []}
                        ambassadorName={ambassadorName}
                        contributorsAddedDates={activeThread?.addedDates}
                    />
                </Scrollbar>
            </Box>
            <Divider />
            {!!replyMessageSender && !!replyMessageContent && (
                <ChatMessageReply onCancel={handleReplyCancel} replyMessageSender={replyMessageSender} replyMessageContent={replyMessageContent} />
            )}
            {!!editMessageDate && !!editMessageContent && (
                <ChatMessageEdit onCancel={handleEditCancel} editMessageDate={editMessageDate} editMessageContent={editMessageContent} />
            )}
            <ChatMessageAdd sendDisabled={!!tooManyMessagesWarning || !!messageTooLongWarning || sendLoading} disabled={!!tooManyMessagesWarning || sendLoading} body={body} onMessageChange={handleChange} onSend={handleSend} />
            {
                !!tooManyMessagesWarning && (
                    <Box sx={{ color: "error.main", textAlign: "center", fontSize: "0.8em" }}>
                        {tooManyMessagesWarning}
                    </Box>
                )
            }
            {
                !!messageTooLongWarning && (
                    <Box sx={{ color: "error.main", textAlign: "center", fontSize: "0.8em" }}>
                        {messageTooLongWarning}
                    </Box>
                )
            }
        </Stack>
    );
};

ChatThread.propTypes = {
    activeAmbassadorID: PropTypes.string.isRequired,
    ambassadors: PropTypes.object,
    activeThreadID: PropTypes.string,
    activeThread: PropTypes.object,
    onTabChange: PropTypes.func,
    onToggleSideBar: PropTypes.func,
    showToggle: PropTypes.bool,
    prospectMuted: PropTypes.bool,
};

import PropTypes from 'prop-types';
import { formatDistanceStrict, formatDistanceToNowStrict } from 'date-fns';
import ArchiveIcon from '@untitled-ui/icons-react/build/esm/Archive';
import Bell01Icon from '@untitled-ui/icons-react/build/esm/Bell01';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import Camera01Icon from '@untitled-ui/icons-react/build/esm/Camera01';
import DotsHorizontalIcon from '@untitled-ui/icons-react/build/esm/DotsHorizontal';
import PhoneIcon from '@untitled-ui/icons-react/build/esm/Phone';
import SlashCircle01Icon from '@untitled-ui/icons-react/build/esm/SlashCircle01';
import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import {
    Avatar,
    AvatarGroup,
    Badge,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    SvgIcon,
    Tooltip,
    Typography
} from '@mui/material';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { usePopover } from 'src/hooks/use-popover';
import { Report } from '@mui/icons-material';
import { isAmbassadorAvailable } from 'src/utils/ambassadors';


export const ChatThreadToolbar = (props) => {
    const { prospectMuted, onMuteToggle, targetAmbassadorID, ambassadors, showToggle, onToggleSideBar, participants = [], ...other } = props;
    const user = useMockedUser();
    const popover = usePopover();

    // Maybe use memo for these values

    const recipient = ambassadors.byId[targetAmbassadorID];
    const displayName = recipient.firstName;
    const byLine = (recipient.ambassadorType ?? 'Ambassador') + (recipient.program ? ` | ${recipient.program}` : '');

    const isAvailable = isAmbassadorAvailable(recipient);

    return (
        <>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    flexShrink: 0,
                    minHeight: 64,
                    px: 2,
                    py: 1
                }}
                {...other}>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    {showToggle && (
                        <Tooltip title="Other threads">
                            <IconButton onClick={onToggleSideBar}>
                                <SvgIcon>
                                    <KeyboardDoubleArrowRightIcon />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                    <AvatarGroup
                        max={2}
                    // sx={{
                    //     ...(recipients.length > 1 && {
                    //         '& .MuiAvatar-root': {
                    //             height: 30,
                    //             width: 30,
                    //             '&:nth-of-type(2)': {
                    //                 mt: '10px'
                    //             }
                    //         }
                    //     })
                    // }}
                    >
                        {/* {recipients.map((recipient) => ( */}
                        <Avatar
                            key={recipient.id}
                            src={recipient.photoURL || undefined}
                        />
                        {/* ))} */}
                    </AvatarGroup>
                    <div>
                        <Typography textAlign="left" variant="subtitle2">
                            {displayName}
                        </Typography>

                        <Typography
                            textAlign="left"
                            color="text.secondary"
                            variant="caption"
                            sx={{ float: 'left' }}
                        >
                            {byLine}
                        </Typography>
                    </div>
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={1}
                >
                    {/* <IconButton>
                        <SvgIcon>
                            <PhoneIcon />
                        </SvgIcon>
                    </IconButton>
                    <IconButton>
                        <SvgIcon>
                            <Camera01Icon />
                        </SvgIcon>
                    </IconButton> */}
                    <Typography sx={{ marginBottom: "auto", marginRight: "2em", fontSize: "0.7em" }}>
                        <Badge sx={{ "& .MuiBadge-badge": { backgroundColor: isAvailable ? "#4CBB17" : "#F4BB44", minWidth: "unset !important", width: "11px", height: "12px", marginRight: "13px", top: "-13px" } }} badgeContent=" " overlap="circular" anchorOrigin={{ vertical: "bottom", horizontal: "right" }} />
                        {isAvailable ? "Online Now" : "Online Soon"}
                    </Typography>
                    <Tooltip title="More options">
                        <IconButton
                            onClick={popover.handleOpen}
                            ref={popover.anchorRef}
                        >
                            <SvgIcon>
                                <DotsHorizontalIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
            <Menu
                anchorEl={popover.anchorRef.current}
                keepMounted
                onClose={popover.handleClose}
                open={popover.open}
            >
                <MenuItem onClick={() => {
                    onMuteToggle();
                    // popover.handleClose();
                }}>
                    <ListItemIcon>
                        <SvgIcon>
                            {prospectMuted ? <Bell01Icon /> : <NotificationsOffIcon />}
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary={prospectMuted ? "Unmute" : "Mute"} />
                </MenuItem>
                {/* <MenuItem>
                    <ListItemIcon>
                        <SvgIcon>
                            <SlashCircle01Icon />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary="Block" />
                </MenuItem> */}
                {/* <MenuItem sx={{
                    "&:hover": {
                        color: 'darkred',
                    }
                }}>
                    <ListItemIcon sx={{ color: 'inherit' }}>
                        <SvgIcon sx={{ color: 'inherit' }}>
                            <Report sx={{ color: 'inherit' }} />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary="Report & Block" />
                </MenuItem> */}
                {/* <MenuItem>
                    <ListItemIcon>
                        <SvgIcon>
                            <ArchiveIcon />
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText primary="Archive" />
                </MenuItem> */}
            </Menu>
        </>
    );
};

ChatThreadToolbar.propTypes = {
    targetAmbassadorID: PropTypes.string,
    ambassadors: PropTypes.object,
    participants: PropTypes.array,
    onToggleSideBar: PropTypes.func,
    showToggle: PropTypes.bool,
    prospectMuted: PropTypes.bool,
    onMuteToggle: PropTypes.func,
};
